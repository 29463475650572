"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createApolloClient = void 0;
var client_1 = require("@apollo/client");
var cookie_1 = require("cookie");
var hooks_1 = require("./hooks");
exports.createApolloClient = function () {
    var authMiddleWare = new client_1.ApolloLink(function (operation, forward) {
        var _a = cookie_1.parse(document.cookie), _b = hooks_1.AUTH_TOKEN_NAME, authToken = _a[_b];
        if (authToken) {
            operation.setContext({
                headers: {
                    authorization: "Bearer " + authToken,
                },
            });
        }
        return forward(operation);
    });
    var httpLink = new client_1.HttpLink({ uri: '/graphql' });
    var cache = new client_1.InMemoryCache();
    return new client_1.ApolloClient({
        link: authMiddleWare.concat(httpLink),
        cache: cache,
    });
};
