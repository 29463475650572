"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.groupIdOptions = exports.ordinalSuffix = exports.getPlateAppearanceLabel = exports.getPositionAbbreviation = void 0;
var gql_1 = require("./gql");
exports.getPositionAbbreviation = function (position) {
    var _a;
    return ((_a = {},
        _a[gql_1.FieldingPosition.PITCHER] = 'P',
        _a[gql_1.FieldingPosition.CATCHER] = 'C',
        _a[gql_1.FieldingPosition.FIRST_BASE] = '1B',
        _a[gql_1.FieldingPosition.SECOND_BASE] = '2B',
        _a[gql_1.FieldingPosition.THIRD_BASE] = '3B',
        _a[gql_1.FieldingPosition.SHORTSTOP] = 'SS',
        _a[gql_1.FieldingPosition.LEFT_FIELD] = 'LF',
        _a[gql_1.FieldingPosition.CENTER_FIELD] = 'CF',
        _a[gql_1.FieldingPosition.LEFT_CENTER] = 'LCF',
        _a[gql_1.FieldingPosition.RIGHT_CENTER] = 'RCF',
        _a[gql_1.FieldingPosition.RIGHT_FIELD] = 'RF',
        _a[gql_1.FieldingPosition.MIDDLE_INFIELD] = 'MI',
        _a)[position]);
};
exports.getPlateAppearanceLabel = function (paType) {
    var _a;
    return ((_a = {},
        _a[gql_1.PlateAppearanceType.OUT] = 'Out',
        _a[gql_1.PlateAppearanceType.SINGLE] = 'Single',
        _a[gql_1.PlateAppearanceType.DOUBLE] = 'Double',
        _a[gql_1.PlateAppearanceType.TRIPLE] = 'Triple',
        _a[gql_1.PlateAppearanceType.HOMERUN] = 'Home Run',
        _a[gql_1.PlateAppearanceType.WALK] = 'Walk',
        _a[gql_1.PlateAppearanceType.SACRIFICE_FLY] = 'Sacrifice Fly',
        _a[gql_1.PlateAppearanceType.FIELDERS_CHOICE] = "Fielder's Choice",
        _a[gql_1.PlateAppearanceType.DOUBLE_PLAY] = 'Double Play',
        _a)[paType]);
};
exports.ordinalSuffix = function (n) {
    return Math.floor(n / 10) === 1
        ? 'th'
        : n % 10 === 1
            ? 'st'
            : n % 10 === 2
                ? 'nd'
                : n % 10 === 3
                    ? 'rd'
                    : 'th';
};
exports.groupIdOptions = function (groupId, variables) { return ({
    variables: groupId ? __assign({ groupId: groupId }, variables) : undefined,
    skip: !groupId,
}); };
