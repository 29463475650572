"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var grommet_1 = require("grommet");
var hooks_1 = require("../hooks");
var initialState = { email: '', password: '' };
var LoginModal = function (_a) {
    var visible = _a.visible, onClose = _a.onClose;
    var _b = react_1.useState(initialState), formValues = _b[0], setFormValues = _b[1];
    var _c = react_1.useState(), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = react_1.useState(), successMessage = _d[0], setSuccessMessage = _d[1];
    var handleClose = react_1.useCallback(function () {
        onClose();
        setFormValues(initialState);
    }, [onClose, setFormValues]);
    var handleCompleted = react_1.useCallback(function (data) {
        var _a;
        if (!((_a = data.login) === null || _a === void 0 ? void 0 : _a.jwt)) {
            setErrorMessage('Invalid username or password.');
        }
        else {
            setSuccessMessage('Successfully logged in.');
            handleClose();
        }
    }, [setErrorMessage, setSuccessMessage, formValues, handleClose]);
    var _e = hooks_1.useLogin(handleCompleted), login = _e[0], _f = _e[1], loading = _f.loading, error = _f.error;
    react_1.useEffect(function () {
        if (error) {
            setErrorMessage(error.message);
        }
    }, [error, setErrorMessage]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        !!errorMessage && (react_1.default.createElement(grommet_1.Notification, { toast: true, status: "critical", title: "Login unsuccessful", message: errorMessage, onClose: function () { return setErrorMessage(undefined); } })),
        !!successMessage && (react_1.default.createElement(grommet_1.Notification, { toast: true, status: "normal", title: successMessage, onClose: function () { return setSuccessMessage(undefined); } })),
        visible && (react_1.default.createElement(grommet_1.Layer, { onClickOutside: handleClose, background: "transparent", responsive: false },
            react_1.default.createElement(grommet_1.Box, { pad: { horizontal: 'medium', bottom: 'medium' }, width: "medium", background: "light-2" },
                react_1.default.createElement(grommet_1.Heading, { level: 3 }, "Login"),
                react_1.default.createElement(grommet_1.Form, { value: formValues, onChange: setFormValues, onSubmit: function (_a) {
                        var value = _a.value;
                        return login(value);
                    } },
                    react_1.default.createElement(grommet_1.FormField, { label: "Email", name: "email", htmlFor: "login-email", required: true, validate: [
                            {
                                regexp: /^[a-z0-9\._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                                message: 'Invalid email address.',
                            },
                        ] },
                        react_1.default.createElement(grommet_1.TextInput, { name: "email", type: "email", id: "login-email" })),
                    react_1.default.createElement(grommet_1.FormField, { label: "Password", name: "password", htmlFor: "login-password", required: true },
                        react_1.default.createElement(grommet_1.TextInput, { name: "password", type: "password", id: "login-password" })),
                    react_1.default.createElement(grommet_1.Box, { direction: "row", justify: "between" },
                        react_1.default.createElement(grommet_1.Button, { label: "Cancel", onClick: handleClose }),
                        react_1.default.createElement(grommet_1.Button, { primary: true, type: "submit", label: "Submit", disabled: loading, icon: loading ? react_1.default.createElement(grommet_1.Spinner, null) : undefined }))))))));
};
exports.default = LoginModal;
